import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './css/rankings.css'


const Rankings = () => {
  const API_URL = useSelector((state) => state.settings.agentApiUrl);
  const [teams, setTeams] = useState([]);
  const [currentTeam, setCurrentTeam] = useState('');

  useEffect(() => {
    const fetchDataAndUpdateTable = async () => {
      try {
        const response = await fetch(`${API_URL}/get_comp_table`, {
          method: "GET",
          headers: {
            "Accept": "application/json"
          }
        });
        const data = await response.json();
        const sortedTeams = data.teams.sort((a, b) => b.score - a.score).slice(0, 10);
        setTeams(sortedTeams);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const updateCurrentTeamName = () => {
      const currentTeamName = localStorage.getItem("currentTeam");
      setCurrentTeam(currentTeamName);
    };

    fetchDataAndUpdateTable();
    updateCurrentTeamName();

    const intervalId = setInterval(() => {
      fetchDataAndUpdateTable();
      updateCurrentTeamName();
    }, 5000);

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  return (
    <section className="main-container">
    <table id="ranking-table">
        <thead>
          <tr>
            <th>RANK</th>
            <th>TEAM NAME</th>
            <th>ANSWERED QUESTIONS</th>
            <th>ATTEMPTED QUESTIONS</th>
            <th>SCORE</th>
          </tr>
        </thead>
        <tbody>
          {teams.map((team, index) => (
            <tr key={index} style={{ backgroundColor: team.color, color: 'black' }}>
              <td>{index + 1}</td>
              <td>{team.name}</td>
              <td>{team.solved_questions}</td>
              <td>{team.attempted_questions}</td>
              <td>{team.score}</td>
            </tr>
          ))}
        </tbody>
      </table>
  </section>
  );
}

export default Rankings;