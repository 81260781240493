import '../../css/App.css';
import './css/login.css';
import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login, checkTokenExpiry } from '../../slices/authSlice';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';


function Login() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Team, setTeam] = useState({ name: '', password: ''});
  const [isSignUp, setIsSignUp] = useState(false);
  const [shake, setShake] = useState(false);
  const API_URL = useSelector((state) => state.auth.ApiUrl);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  
  useEffect(() => {
    const tokenExpired = dispatch(checkTokenExpiry());
    if (!isAuthenticated || currentUser.role !== "student" || tokenExpired) {
      // Redirect to the home page if not authenticated
      navigate('/VCC/Login');
    }
    else if (isAuthenticated || currentUser.role === "student") {
      navigate('/VCC/Quiz');
    }
  }, [navigate, isAuthenticated, currentUser]);

  const handleChange = (e) =>{
    setTeam(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleLogin = async () => {
    if (!Team.name.trim() || !Team.password.trim()) {
      setShake(true);
      setTimeout(() => setShake(false), 1000);
       toast.error('Please Enter all the fields');
        return;
      }
      console.log(API_URL)
    fetch(`${API_URL}/team_login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ team_name: Team.name, password: Team.password}),
    })
    .then(response => response.json())
    .then(data => {        
      if (data.status === "success") {
        const decoded = jwtDecode(data.data);
        dispatch(login({ token: data.data, name: decoded.sub, role: decoded.role, exp: decoded.exp }));
        navigate("/VCC/Quiz")
      } else if (data.status === "failed"){
        toast.error(data.message, {
          position: "top-center"
        })
      }
      
    })
    .catch(error => console.error('Error:', error));

  };

  const handleSignUp = async () => {

    fetch(`${API_URL}/team_signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ team_name: Team.name, password: Team.password}),
    })
  .then(response => response.json())
  .then(data => {        
    if (data.status === "success") {
      const decoded = jwtDecode(data.data);
      dispatch(login({ token: data.data, name: decoded.sub, role: decoded.role, exp: decoded.exp }));
      navigate("/VCC/Quiz")
    } else if (data.status === "failed"){
      toast.error(data.message, {
        position: "top-center"
      })
    }
    
  })
  .catch(error => console.error('Error:', error));
  };

  const handleSubmit = async () => {
    if (!Team.name.trim() || !Team.password.trim()) {
      setShake(true);
      setTimeout(() => setShake(false), 1000);
       toast.error('Please Enter all the fields', {
        position: "top-center"
      });
        return;
      }
    try {
      if (isSignUp) {
        await handleSignUp();
      } else {
        await handleLogin();
      }
    } catch (error) {
      console.error(`Error during ${isSignUp ? 'sign up' : 'login'}:`, error);
      toast.error(`An error occurred during ${isSignUp ? 'sign up' : 'login'}`, {
        position: "top-center"
      });
    }

  };
  return (
    <div className="main-container">
      <div className="container" id="login-container">
        <div className="input-group">
          <h1>Username:</h1>
          <input
            type="text"
            id="team_name"
            name="name"
            onChange={handleChange}
            className={shake ? 'shake' : ''}
          />
        </div>
        <div className="input-group">
          <h1>Password:</h1>
          <input
            type="password"
            id="team_password"
            name="password"
            onChange={handleChange}
            className={shake ? 'shake' : ''}
          />
        </div>
        <button onClick={handleSubmit}>{isSignUp ? 'Sign Up' : 'Login'}</button>
        <button onClick={() => setIsSignUp(!isSignUp)}>
          {isSignUp ? 'Already have an account? Log In' : 'Need an account? Sign Up'}
        </button>
      </div>
    </div>
  );
}

export default Login;