import React, { useState, useEffect } from 'react';
import './css/quiz.css'
import PureMarkdown from '../Utilities/PureMarkdown';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setQuestions, nextQuestion, previousQuestion } from '../../slices/questionSlice';
import { checkTokenExpiry } from '../../slices/authSlice';
import McqsQuestion from './McqsQuestion';
import ShortQuestion from './ShortQuestion';
import QuestionTable from './QuestionTable';
import { toast } from 'react-toastify';

function Quiz() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const API_URL = useSelector((state) => state.auth.ApiUrl);
    const currentUser = useSelector((state) => state.auth.currentUser);
    const token = useSelector((state) => state.auth.token);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const currentQuestion = useSelector((state) => state.questions.currentQuestion);
    const currentQuestionIndex = useSelector((state) => state.questions.currentQuestionIndex);
    const questions = useSelector((state) => state.questions.questions);
    const score = useSelector((state) => state.questions.score);

    useEffect(() => {
        const tokenExpired = dispatch(checkTokenExpiry());
        if (!isAuthenticated || currentUser.role !== "student" || tokenExpired) {
          // Redirect to the home page if not authenticated
          navigate('/VCC/Login');
        }
      }, [navigate]);

  useEffect(() => {
    const fetchQuestions = async () => {
      fetch(`${API_URL}/questions`, {
        method: "GET",
        headers: { 'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` }
      })
      .then(response => response.json())
      .then(data => {        
        if (data.status === "success") {
          console.log(data);
          dispatch(setQuestions(data.data));
        } else if (data.status === "failed"){
          toast.error(data.message, {
            position: "top-center"
          })
        }
        
      })
      
    };
    fetchQuestions();
    
  }, []);


  return (<div>
        <QuestionTable questions={questions}/>
        <div className="main-container">
            <div className="container" id="quiz-container">
                <p id="score-display">Score: {score}</p>
                {currentQuestion && (
                    <div>
                        <h3>{currentQuestion.group}</h3>
                        
                        {currentQuestion.type === 'mcqs' || currentQuestion.type === 'img_mcqs' ? (
                                <McqsQuestion API_URL={API_URL} token={token} question={currentQuestion} />
                            ) : currentQuestion.type === 'short answer' ? (
                                <ShortQuestion API_URL={API_URL} token={token} question={currentQuestion} />
                            ) : null}
                    </div>
                )}
            </div>
        </div>
        </div>
    );
}

export default Quiz;