import React from 'react';
import '../css/Navbar.css'
import { Link, useLocation, useNavigate } from 'react-router-dom';

function Navbar2023() {
  const location = useLocation();
  const navigate = useNavigate();

  const adminRoutes = ['/VCC/Admin'];

  const isAdminRoute = adminRoutes.includes(location.pathname);

  const handleLogout = () => {
    sessionStorage.removeItem('admin_token');
    navigate('/VCC/AdminLogin');
  };

    return (
      <nav className="navbar">
        {isAdminRoute ? (
        <button  onClick={handleLogout} className="logout-button" >Logout</button>
        ) : (
          <>
        <Link to="/">Home</Link>
        <Link to="/VCC/VCCHome"> VCC Home</Link>
        <Link to="/VCC/Rankings">Rankings</Link>
        <Link to="/VCC/Tutorial">API Tutorial</Link>
        <Link to="/VCC/AgentTutorial">Agent Tutorial</Link>
        </>
        )}
  </nav>
    );
  }

export default Navbar2023;