import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';


function CreateTeam({API_URL,accessToken}) {

    const [Team, setTeam] = useState({ name: '', password: ''});
    const [shake, setShake] = useState(false);
  
    const handleChange = (e) =>{
        setTeam(prev => ({
        ...prev,
        [e.target.name]: e.target.value,
        }));
    };

    const createTeam = async () => {
        if (Team.name.trim() === "" || Team.password.trim() === "") {
            setShake(true);
            setTimeout(() => setShake(false), 1000);
            toast.error("Team Name or Password cannot be empty", {
                position: "top-center"
            });
            return;
        }

        fetch(`${API_URL}/team_signup`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({ name: Team.name, password: Team.password }),
          })
            .then(response => response.json())
            .then(data => {
      
              if (data.status === "success") {
                toast.success(data.message, {
                    position: "top-center"
                });
              } else if (data.status === "failed") {
                toast.error(data.message, {
                    position: "top-center"
                });
              }
            })
            .catch(error => {
                console.log(error);
                toast.error(`Failed to add Team`, {
                    position: "top-center"
                });
            });

    }
    return (
        
          <div className="container" id="login-container">
            <div className="input-group">
              <h1>Team Name:</h1>
              <input
                type="text"
                id="team_name"
                name="name"
                onChange={handleChange}
                className={shake ? 'shake' : ''}
              />
            </div>
            <div className="input-group">
              <h1>Password:</h1>
              <input
                type="password"
                id="team_password"
                name="password"
                onChange={handleChange}
                className={shake ? 'shake' : ''}
              />
            </div>
            <button onClick={createTeam}>Create Team</button>
          </div>
      );

}

export default CreateTeam;