import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login, checkTokenExpiry } from '../../slices/authSlice';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';


function AdminLogin() {
    const [admin, setAdmin] = useState({ name: '', password: '' });
    const [shake, setShake] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const API_URL = useSelector((state) => state.auth.ApiUrl);
    const currentUser = useSelector((state) => state.auth.currentUser);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    
    useEffect(() => {
      const tokenExpired = dispatch(checkTokenExpiry());
      if (!isAuthenticated || currentUser.role !== "admin" || tokenExpired) {
        // Redirect to the home page if not authenticated
        navigate('/VCC/AdminLogin');
      }
    }, [navigate, isAuthenticated, currentUser]);
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setAdmin(prev => ({
          ...prev,
          [name]: value,
        }));
      };

      const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission
    
        if (!admin.name.trim() || !admin.password.trim()) {
          setShake(true);
          setTimeout(() => setShake(false), 1000);
          toast.error('Please Enter all the fields', {
            position: "top-center"
          });
          return;
        }

        fetch(`${API_URL}/admin_login`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name: admin.name, password: admin.password}),
          })
          .then(response => response.json())
          .then(data => {       
            if (data.status === "success") {
              const decoded = jwtDecode(data.data);
              dispatch(login({ token: data.data.access_token, name: decoded.sub, role: decoded.role }));
              navigate("/VCC/Admin");
              
            } else if (data.status === "failed"){
              toast.error(data.message, {
                position: "top-center"
              })
            }
            
          })
          .catch(error => {
            console.error('Error:', error)
            toast.error('An error occurred during login', {
                position: "top-center"
              });
            });
        };


    return (
    <div className="login-main-container">
        <div className="login-container" id="login-container">
        <form onSubmit={handleSubmit}>
            <div className="input-group">
            <h1>Username:</h1>
            <input
                type="text"
                id="admin_name"
                name="name"
                value={admin.name}
                onChange={handleChange}
                className={shake ? 'shake' : ''}
            />
            </div>
            <div className="input-group">
            <h1>Password:</h1>
            <input
                type="password"
                id="admin_password"
                name="password"
                value={admin.password}
                onChange={handleChange}
                className={shake ? 'shake' : ''}
            />
            </div>
            <button type="submit" className='submit-button'>Login</button>
        </form>
        </div>
    </div>
    );
}

export default AdminLogin;