import React, { useState, useEffect } from 'react';
import './css/quiz.css'
import PureMarkdown from '../Utilities/PureMarkdown';
import { useDispatch, useSelector } from 'react-redux';
import { setScore, setAnswerStatus, setAttempts, setUserAnswer } from '../../slices/questionSlice';

const McqsQuestion = ({API_URL, token, question}) => {
    const dispatch = useDispatch();
    const userAnswers = useSelector((state) => state.questions.answers); // Access stored answers
    
    const handleAnswerChange = (e) => {
        dispatch(setUserAnswer({
          questionId: question.id,
          answer: e.target.value,
        }));
      };
    
    
    const submitAnswer = () => {
        fetch(`${API_URL}/submit_mcqs_answer`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` },
        body: JSON.stringify({
            "id": question.id.toString(),
            "answer":userAnswers[question.id]
        }),
        })
        
        .then(response => response.json())
        .then(data => {
            console.log(data)
        if (data.status === "success" && data.data.includes("Correct")) {
            dispatch(setScore(parseInt(question.current_points)));
            dispatch(setAnswerStatus('correct'));
        } else if (data.status === "success" && data.data.includes("Incorrect")){
            dispatch(setAnswerStatus('incorrect'));
        } else if (data.status === "failed"){
            alert(data.message);
        }
    })
    .catch(error => {
      console.error('Error submitting answer:', error);
    });   
    console.log(question.answerStatus)
    }

    return(
        
        <div className="question-container">
            <h3>{question.id}. {question.title}</h3>
            <b>For {question.current_points} pts:</b>
            <PureMarkdown content={question.content} />
            {question.content_link && (
                <a href={`${process.env.PUBLIC_URL}/${question.content_link}`} target="_blank" rel="noopener noreferrer">{question.content_link}</a>
            )}
            {question.image_link && (
            <img src={`${process.env.PUBLIC_URL}/${question.image_link}`} alt="Question" style={{ width: '100%', display: question.image_link ? 'block' : 'none', marginBottom: '20px' }} />
            )}
            <div>
                {question.options.map((option, index) => (
                    <label className="normal-font-weight" key={index}>
                        <input
                            type="radio"
                            name="option"
                            value={String.fromCharCode(97 + index)}
                            checked={userAnswers[question.id] === String.fromCharCode(97 + index)}
                            onChange={handleAnswerChange}
                        /> {<span dangerouslySetInnerHTML={{ __html: option }} />}
                    </label>
                ))}
                
            </div>
            <button style={{
                backgroundColor: question.answerStatus === 'correct' ? 'palegreen' :
                question.answerStatus === 'incorrect' ? 'grey' :
                question.solved === 1 ? 'palegreen':
                question.solved === 0 ? 'grey' : '#4CAF50'}}
                disabled={question.solved === 1}
            onClick={submitAnswer}>Submit</button>
        </div>
    )

}

export default McqsQuestion;