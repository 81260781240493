import './css/App.css';
import React from 'react';

function HomePage() {
  return (
        <h1>HOME PAGE</h1>

  );
}

export default HomePage;