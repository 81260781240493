import React from 'react';
//import './css/tutorial.css'
import PureMarkdown from '../VCC/Utilities/PureMarkdown';


const content = `
# Elf Factory Optimization Challenge

## Background
The North Pole's toy factory is gearing up for the holiday season, and Santa needs your help to optimize the production line! You've been given a list of toys that need to be made, along with their production times. You also have information about the elves who will be making these toys, including their skill levels.

## Your Task
Your mission is to create a program that optimizes the toy production schedule. The program should determine:

1. The maximum number of toys that can be produced in a 24-hour period.
2. A human-readable schedule showing which elf makes which toys and in what order.

## Input Data
You will be provided with two files:

1. [\`toy_data.txt\`](/files/questions/q2/toy_data.txt): Contains information about the toys, with each line in the format \`Toy Name,Production Time\`
2. [\`starter_code.py\`](/files/questions/q2/starter_code.py): Contains code for importing toys and creating the elves with their skill levels.
## Requirements
Your program should:

1. Read the toy and elf data from the input files.
2. Implement an algorithm to optimize toy production, considering:
   - The production time for each toy
   - The skill level of each elf (which affects their production speed)
   - A total working period of 24 hours
3. Output:
   - The total number of toys that can be produced in 24 hours
   - A human-readable schedule showing:
     * Which elf is assigned to each toy
     * The order in which each elf will produce their assigned toys
     * The start and end time for the production of each toy

## Constraints
- The production time for a toy is affected by the elf's skill level. For example, if a toy takes 4 hours to produce and an elf has a skill level of 1.2, they will take 4 / 1.2 = 3.33 hours to produce the toy.
- Elves can only work on one toy at a time.
- The total working period is exactly 24 hours. No toy production can start if it can't be completed within the 24-hour period.


## Scoring Breakdown
- Correct number of hours **20pts** 
- Correct schedule with no times **10pts** (see below)
\`\`\`text
Elf Schedule:
Buddy:
- Toy Dollhouse 
- Toy Train 
- Toy Dragon 
- Toy Car 

Jovie:
- Toy Kitchen 
- Toy Race Track
...
\`\`\`
- Correct schedule with correct time of day *10pts* (see below)
\`\`\`text
Elf Schedule:
Buddy:
- Toy Dollhouse (00:00 - 12:51)
- Toy Microscope (12:51 - 21:25)

Jovie:
- Toy Kitchen (00:00 - 07:16)
- Toy Robot Dog (07:16 - 13:38)
...
\`\`\`

BONUS: The elf union has won each elf to have 2 X 90 minute breaks in a 24 hour day. There must be 2 separate breaks and not a 3 single 3 hour break. What is the new total number of toys and schedule with times. **10pts**

\`\`\`text
Total toys produced in 24 hours with breaks: 18

Elf Schedule:
Buddy:
- Toy Dollhouse (00:00 - 12:51)
- Break (12:51 - 14:21)
- Toy Train (14:21 - 20:21)
- Break (20:21 - 21:51)
- Toy Car (21:51 - 24:00)

Jovie:
- Toy Kitchen (00:00 - 07:16)
- Toy Robot Dog (07:16 - 13:38)
- Break (13:38 - 15:08)
- Toy Rocket (15:08 - 19:23)
- Break (19:23 - 20:53)
- Toy Sword (20:53 - 23:23)
...
\`\`\`
`;



const Question2 = () => {

  return (
    <PureMarkdown content={content}/>
  );
}


export default Question2;