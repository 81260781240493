import React from 'react';
//import './css/tutorial.css'
import PureMarkdown from '../VCC/Utilities/PureMarkdown';


const content = `
# Emoji Cipher

In a world where emojis have become the primary form of written communication, a group of friends has developed a secret code using emojis to send hidden messages. Your task is to create an encoder and decoder for this Emoji Cipher.

## The Emoji Cipher Rules

1. Each letter of the alphabet is represented by a specific emoji.
2. The emoji representing each letter changes based on its position in the word.
3. The pattern for changing emojis repeats every 3 letters.
4. Spaces between words are represented by the 🌈 emoji.
5. Punctuation marks are left as they are.

## Emoji Patterns

For each group of 3 letters in a word:
- The 1st letter uses "object" emojis (e.g., 🍎, 🚗, 🎈)
- The 2nd letter uses "face" emojis (e.g., 😊, 😂, 😎)
- The 3rd letter uses "animal" emojis (e.g., 🐶, 🐱, 🐭)

## Your Task

1. Create an emoji dictionary that assigns an emoji to each letter of the alphabet for each of the three positions.
2. Implement an \`encode\` function that converts a regular text message into an emoji message using the Emoji Cipher rules.
3. Implement a \`decode\` function that converts an emoji message back into regular text.
4. Create a simple interface that allows users to encode and decode messages.

## Example

Original message: 
\`\`\`text
Hello World!
\`\`\`

Encoded message:
\`\`\`text
🎈😊🐶🚗😂🐱🍎🌈🎈😊🐶🐱😂🌀
\`\`\`

## Scoring Points

1. Demonstrate the example above in your code (Type in hello world and see the same encode message) for **20 points**
2. Decode this message for **20 points**:
\`\`\`text
🎁😅🌈🍇😆🐱☂️😂🐻🎁😉🐻🌈🎁😗🌈🌸🤣🐰🌈📚😘🐵🍒😆🐤⚽️🌈🔑😅🌈🎵😆🐷🔑😋🐨⏰😂🌈🍌😚🐻⚽️,🌈🌸🤣🐰⏰🌈📚😘🐵🍑😘🐶🚀🙃🐨⏰😂🌈🚀😚🐤🌸🌈🍌😆🌈🌸🤣🐰🌈📚😘🐵🍒😆🐤⚽️🌈🔑😅🌈📚😚🦆🌸☺️🐸🍑🌈🌸🤣🐰🚀🌈🎁😉
\`\`\`
3. HARD BONUS for **10 points**: Create a live encoder so that when you type letters emojis come out:

Your program should be able to correctly encode and decode messages using the Emoji Cipher rules.

**Link to starter code (also displayed below):** [\`starter_code.py\`](/files/questions/q1/starter_code.py)

\`\`\`python
# Emoji dictionaries for each position
EMOJI_DICT_1 = {
    'a': '🍎', 'b': '🍌', 'c': '🍒', 'd': '🍇', 'e': '🍉', 'f': '🍓',
    'g': '🍑', 'h': '🎈', 'i': '🎁', 'j': '🎨', 'k': '🚗', 'l': '🚲',
    'm': '🚀', 'n': '⏰', 'o': '🔑', 'p': '📚', 'q': '💡', 'r': '🎵',
    's': '⚽️', 't': '🌸', 'u': '☂️', 'v': '🍁', 'w': '❄️', 'x': '🌞',
    'y': '✨', 'z': '🌈'
}

EMOJI_DICT_2 = {
    'a': '😀', 'b': '😃', 'c': '😄', 'd': '😁', 'e': '😆', 'f': '😅',
    'g': '😂', 'h': '🤣', 'i': '☺️', 'j': '😊', 'k': '😇', 'l': '🙂',
    'm': '🙃', 'n': '😉', 'o': '😌', 'p': '😍', 'q': '🥰', 'r': '😘',
    's': '😗', 't': '😙', 'u': '😚', 'v': '😋', 'w': '😛', 'x': '😝',
    'y': '😜', 'z': '🤪'
}

EMOJI_DICT_3 = {
    'a': '🐶', 'b': '🐱', 'c': '🐭', 'd': '🐹', 'e': '🐰', 'f': '🦊',
    'g': '🐻', 'h': '🐼', 'i': '🐨', 'j': '🐯', 'k': '🦁', 'l': '🐮',
    'm': '🐷', 'n': '🐸', 'o': '🐵', 'p': '🐔', 'q': '🐧', 'r': '🐦',
    's': '🐤', 't': '🦆', 'u': '🦅', 'v': '🦉', 'w': '🦇', 'x': '🐺',
    'y': '🐗', 'z': '🐴'
}

def encode(message):
    # OPTIONAL: Implement the encoding function
    pass

def decode(emoji_message):
    # Implement the decoding function
    pass

def main():
    while True:
        choice = input("Enter 'e' to encode, 'd' to decode, or 'q' to quit: ")
        
        if choice == 'e':
            message = input("Enter the message to encode: ")
            encoded_message = encode(message)
            print("Encoded message:", encoded_message)
        
        elif choice == 'd':
            emoji_message = input("Enter the emoji message to decode: ")
            decoded_message = decode(emoji_message)
            print("Decoded message:", decoded_message)
        
        elif choice == 'q':
            break
        
        else:
            print("Invalid choice. Please try again.")
\`\`\`


`;



const Question1 = () => {

  return (
    <PureMarkdown content={content}/>
  );
}


export default Question1;